<template>
    <div class="declined-cityads">
        <div class="declined-cityads_first-container">
            <div class="declined-cityads_inner">
                <h1 class="declined-cityads_title">Заявка отклонена</h1>
                <p class="declined-cityads_text mb-4">Нам очень жаль, но мы не можем одобрить рассрочку на покупку</p>
                <p @click="isOpenModal = true" class="declined-cityads_text_linked">Возможные причины отказа</p>
            </div>
        </div>
        <h2 class="declined-cityads_text">Партнеры с <span>меньшими требованиями</span>, готовые выдать деньги на карту:</h2>
        <div class="declined-cityads_partners">
            <div
                v-for="partner in partners"
                :key="partner.name"
                class="declined-cityads_partner"
                >
                <img :src="require(`@/assets/img/cityads/${partner.image}`)" class="declined-cityads_partner_img">
                <p class="declined-cityads_partner_sum mb-4">{{partner.sum}}</p>
                <a :href="partner.link" class="declined-cityads_partner_link">Получить деньги</a>
            </div>
        </div>
        <p class="declined-cityads_text mb-4 text-left">Не согласны с решением или хотите его пересмотреть?</p>
        <p class="declined-cityads_text text-left">Напишите нам на <a href="mailto:otkaz@paylate.info">otkaz@paylate.info</a>. Рассмотрим обращение в течение 3х рабочих дней. В письме укажите ФИО и мобильный телефон.</p>
        <div v-if="isOpenModal" class="declined-cityads_modal_overlay"></div>
        <div v-if="isOpenModal"  class="declined-cityads_modal">
<!--            <v-btn-->
<!--                @click="isOpenModal = false"-->
<!--                fab-->
<!--                elevation="1"-->
<!--                class="declined-cityads_modal_close_modal"-->
<!--                >-->

<!--            </v-btn>-->
            <button
                @click="isOpenModal = false"
                class="declined-cityads_modal_close_modal"
                >
                <img src="@/assets/img/cityads/close.svg"/>
            </button>
            <h3 class="declined-cityads_modal_title">Возможные причины отказа</h3>
            <ul class="declined-cityads_modal_list">
                <li
                    v-for="item in declineList"
                    :key="item"
                    class="declined-cityads_modal_list-item"
                    >{{item}}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    name: "DeclinedCityads",
    data(){
        return {
            partners: [
                {
                    name: 'займер',
                    sum: 'до 30 000 ₽',
                    image: 'zaimer.svg',
                    link: 'https://yknhc.com/g/p7fj2irdmxbb206030ba17c8b502f2/?erid=25H8d7vbP8SRTvFafEYFCL'
                },
                {
                    name: 'капуста',
                    sum: 'до 30 000 ₽',
                    image: 'kapusta.svg',
                    link: 'https://fxxag.com/g/kd5dkyy8m9bb206030ba1f5a7758cf/?erid=25H8d7vbP8SRTvGZQjsjcH'
                },
                {
                    name: 'миг',
                    sum: 'до 100 000 ₽',
                    image: 'mig.svg',
                    link: 'https://ficca2021.com/g/4ed19c9aedbb206030ba6f1a920894/?erid=F7NfYUJRWmqqH7m5KLBx'
                },
            ],
            isOpenModal: false,
            declineList: ['негативная кредитная история;',
                'задолженность в Федеральной Службе Судебных Приставов;',
                'некорректные сведения о работодателе;',
                'расчетный кредитный лимит недостаточен для запрашиваемой суммы покупки;',
                'копия паспорта не соответствует требованиям сервиса'
            ]
        }
    }
}
</script>


<style scoped lang="scss">
.declined-cityads {
    margin-top: 50px;

    &_first-container {
        background-color: #FAFAFA;
        border: 1px solid #E7E8EA;
        border-radius: 18px;

    }
    &_inner {
        max-width: 296px;
        width: 100%;
        margin: 60px auto;
    }
    &_title {
        font-family: Roboto, sans-serif;
        font-weight: 900;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: -0.4px;
        color: #505050;
        position: relative;
        margin-bottom: 20px;

        &::after {
            content: '';
            position: relative;
            top: 3px;
            left: 5px;
            background: url("../../assets/img/cityads/sad_emoji.svg") no-repeat;
            width: 20px;
            height: 20px;
            display: inline-block;
        }
    }

    &_text {
        font-family: Roboto, sans-serif !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
        letter-spacing: -0.3px !important;
        color: #505050 !important;

        & > a,span {
            color: #EB3B66;
            text-decoration: none;
        }
        &_linked {
            color: #EB3B66;
            cursor: pointer;
            position: relative;
            font-family: Roboto, sans-serif !important;
            font-size: 14px !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            letter-spacing: -0.3px !important;

            &::after {
                content: '';
                position: relative;
                top: 1px;
                left: 5px;
                background: url("../../assets/img/cityads/chevron.svg") no-repeat;
                width: 8px;
                height: 10px;
                display: inline-block;
            }
        }
    }
    &_partners {
        display: flex;
        gap: 30px;
        justify-content: center;
        margin: 20px 0 32px;
        flex-wrap: wrap;

    }
    &_partner {
        box-sizing: border-box;
        background-color: #FAFAFA;
        border: 1px solid #E7E8EA;
        border-radius: 18px;
        padding: 10px 6px;
        &:hover .declined-cityads_partner_img {
            filter: grayscale(0%);
        }

        &_img {
            filter: grayscale(100%);
            transition: all .7s ease-in-out;
            margin: 20px 0 10px;
        }

        &_sum {
            font-family: Roboto, sans-serif !important;
            font-weight: 800;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.3px;
            color: #505050;
        }

        &_link {
            font-family: Roboto, sans-serif;
            font-size: 13px;
            font-weight: 900;
            line-height: 15px;
            letter-spacing: -0.4px;
            color: #fff;
            text-decoration: none;
            max-width: 160px;
            width: 100%;
            height: 36px;
            background-color: #EB3B66;
            padding: 10px 30px;
            border-radius: 14px;
            margin-bottom: 10px;
        }
    }

    &_modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
        background-color: #fff;
        border-radius: 14px;
        padding: 20px;

        &_title {
            font-family: Roboto, sans-serif;
            font-weight: 900;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: -0.4px;
            color: #505050;
            position: relative;
            margin-bottom: 20px;
        }

        &_list {
            //padding: 0;
            &-item {
                font-family: Roboto, sans-serif !important;
                font-size: 14px !important;
                font-weight: 400 !important;
                line-height: 20px !important;
                letter-spacing: -0.3px !important;
                color: #505050 !important;
                text-align: left;
            }

        }
        &_overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: 0.8;
        }

        &_close_modal {
            background-color: #E7E7E7;
            position: absolute;
            top: 10px;
            right: 10px;
            width: 30px;
            height: 30px;
            z-index: 100;
            border-radius: 50%;
            transition: all .3s ease-in-out;

            &:hover {
                background-color: #D8D8D8;
            }

            & > img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);


            }
        }
    }
}
</style>
