<template>
    <div
        v-if="templateChoose === 'sbp'"
        class="sbp"
        >
        <h2 :class="$root.state.type()"  class="account_form_title">Выберите банк:</h2>
        <div
            :style="gridContainerStyles"
            class="sbp_container"
            >
            <button
                v-for="bank in banksToShow"
                :key="bank.bank_name"
                @click="$emit('selectBank', $event)"
                :id="bank.member_name"
                :data-bank-name="bank.bank_name"
                :class="{'selected-bank': selectedBank !== null && bank.bank_name === selectedBank.bank_name}"
                class="sbp_container_item"
                >
                <img
                    v-if="bank.bank_name !== 'Другой банк'"
                    class="sbp_container_item_image"
                    :src="!bank.showFakeLogo ? bank.logoURL : fakeLogo"
                    :alt="bank.bank_name"
                />
                <p >{{ bank.name }}</p>
            </button>

            <button
                @click="$emit('selectBank', $event, banksToShow)"
                :id="anotherBank.member_name"
                :data-bank-name="anotherBank.bank_name"
                :class="{'selected-bank': selectedBank !== null && anotherBank.selected}"
                class="sbp_container_item"
                >
                <img
                    v-if="otherBanksShowLogo"
                    class="sbp_container_item_image"
                    :src="otherBanksShowLogo.logo"
                    :alt="anotherBank.bank_name"
                />
                <p >{{ anotherBank.bank_name }}</p>
            </button>
        </div>
        <MainButton
            @click="$emit('confirmBank', $event)"
            class="mt-4"
            :style="reqestButtonStyles"
            >
            <span class="sbp_request-button">Далее</span>
        </MainButton>
    </div>
</template>

<script>
import MainButton from "@/components/global/MainButton.vue";

export default {
    name: "SBPContainer",
    // emits: ['selectBank'],
    props: {
        templateChoose: String,
        selectedBank: Object,
        bankList: Array
    },
    components: {MainButton},
    data(){
        return {
            anotherBank: {
                bank_name: 'Другой банк',
                member_name: 'other_banks',
                logo: require("@/assets/img/sbp/sbp_logo.svg"),
                selected: false
            },
            // bankList: [],
            sbpList: [
                {
                    bank_name: 'Сбербанк',
                    name: 'Сбербанк',
                    logo: require("@/assets/img/sbp/sberbank_logo.svg"),
                },
                {
                    bank_name: 'Т-Банк',
                    name: 'Т-Банк',
                    logo: require("@/assets/img/sbp/t-bank.png")
                },
                {
                    bank_name: 'АЛЬФА-БАНК',
                    name: 'Альфабанк',
                    logo: require("@/assets/img/sbp/alphabank_logo.svg")
                },
                {
                    bank_name: 'РНКБ Банк',
                    name: 'РНКБ',
                    logo: require("@/assets/img/sbp/rnbk_logo.svg")
                },
                {
                    bank_name: 'Банк ВТБ',
                    name: 'ВТБ',
                    logo: require("@/assets/img/sbp/vtb_logo.svg")
                },
                {
                    bank_name: 'Райффайзенбанк',
                    name: 'Райффайзен',
                    logo: require("@/assets/img/sbp/rayphayzen_logo.svg")
                },
                {
                    bank_name: 'Банк ОТКРЫТИЕ',
                    name: 'Открытие',
                    logo: require("@/assets/img/sbp/otkritie_logo.svg")
                },
                {
                    bank_name: 'Совкомбанк',
                    name: 'Совкомбанк',
                    logo: require("@/assets/img/sbp/sovkombank_logo.svg")
                },
                {
                    bank_name: 'Почта Банк',
                    name: 'Почта Банк',
                    logo: require("@/assets/img/sbp/pochta_logo.svg")
                },
            ],
            findOtherBankLogoInApi: false,
            fakeLogo: require("@/assets/img/sbp/sbp_logo.svg"),
        }
    },
    computed: {
        isMobile(){
            return this.$vuetify.breakpoint
        },
        gridContainerStyles(){
            let styles = {}
            if(this.isMobile.mdAndDown){
                styles.gridTemplateColumns = '2fr 2fr 2fr'
            }
            return styles
        },
        banksToShow(){
            let titleBanks = this.bankList.filter(bank => bank.front !== null);
            for(let i of titleBanks){
                for(let j of this.sbpList){
                    if(i.bank_name === j.bank_name){
                        i.name = j.name
                    }
                }
            }

            titleBanks.forEach(el => {
                if(!el.logoURL.trim()){
                    el.showFakeLogo = true
                }else if(!this.testingToShowImage(el.logoURL)) {
                    el.showFakeLogo = true
                }
                else {
                    el.showFakeLogo = false
                }
            })
            titleBanks.sort((a, b) => a.front > b.front ? 1 : -1)
            if(this.isMobile.mdAndDown){
                titleBanks = titleBanks.slice(0, 8)
            }
            return titleBanks
        },

        otherBanksShowLogo() {
            const otherBanksLogo = {};
            if(this.selectedBank === null){
                if(!this.findOtherBankLogoInApi){
                    otherBanksLogo.logo = this.anotherBank.logo
                }
            }else {
                if(this.testingToShowImage(this.selectedBank.logoURL)){
                    if(this.banksToShow.find(bank => bank.bank_name === this.selectedBank.bank_name)){

                        otherBanksLogo.logo = this.anotherBank.logo
                    }else {
                        otherBanksLogo.logo = this.selectedBank.logoURL

                    }
                }
                if(!this.testingToShowImage(this.selectedBank.logoURL)){
                    otherBanksLogo.logo = this.anotherBank.logo
                }

            }
            return otherBanksLogo
        },
        reqestButtonStyles(){
            let styles = {
                whiteSpace: 'normal',
                minHeight: '64px',
                height: '100% !important',
                padding: '10px 20px'
            }

            return styles

        },

    },
    methods: {
        testingToShowImage(str){
            let regExp = [/http/, /https/]
            let flag = false
            regExp.forEach(item => {
                if(item.test(str)){
                    flag = true
                }else {
                    flag = false
                }
            })
            return flag
        },
    }
}
</script>



<style scoped lang="scss">
.sbp {
    font-family: 'Roboto';

    &_title {
        font-family: Roboto !important;
        font-size: 12px !important;
        font-weight: 700 !important;
        line-height: 16px !important;
        letter-spacing: -0.08px !important;
        text-align: center !important;
        margin-bottom: 20px;
    }

    &_container {
        display: grid;
        justify-content: space-between;
        justify-items: center;
        // flex-wrap: wrap;
        grid-template-columns: repeat(5, 1fr);
        gap: 40px;

        @media (max-width: 400px) {
            gap: 30px;
        }
        @media (max-width: 350px) {
            gap: 20px;
        }
        @media (max-width: 330px) {
            gap: 10px;
        }

        &_item {
            width: 82px;
            height: 82px;
            padding: 16px 8px 16px 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 12px;
            box-shadow: 1px 8px 25px 0px rgba(149,157,165,0.19);
            -webkit-box-shadow: 1px 8px 25px 0px rgba(149,157,165,0.19);
            -moz-box-shadow: 1px 8px 25px 0px rgba(149,157,165,0.19);
            margin-bottom: 20px;

            &_image {
                width: 40px;
                height: 40px;
            }

            p {
                font-size: 10px !important;
                font-weight: 700 !important;
                line-height: 16px;
                letter-spacing: -0.08px;
                margin: 0 !important;
                color: #0A0A0D !important;
            }
        }
    }

}

.sbp_request-button {
    // font-size: 1rem;
    display: block;
    max-width: 100%;
    overflow-wrap: break-word;
}
</style>
