<template>
    <div>
        <div class="need-more-data_subtitle" v-if="!checkFieldsForEsia">
            <MarkInfo class="need-more-data_mark" type='exclamation'/>
<!--            <p >Нам нужно ещё немного информации. Пожалуйста, прикрепите необходимые документы.</p>-->
            <p>{{additionalText}}</p>
        </div>
        <EsiaPanel class="mt-12" v-if="checkFieldsForEsia" :step="3"/>
        <v-form ref="form">
            <div v-for="field in fields" :key="field.title">
                <AdditionalForms
                    :fields="field.items"
                    :conditions="field.conditions"
                    :title="field.title"
                    class="mt-4"
                />
            </div>

            <DocsInfo v-if="needPhoto" />

            <p class="error-text" v-if="errorText">{{ errorText }}</p>

            <v-btn
                :loading="loading"
                :disabled="loading"
                @click="sendDocs"
                :class="$root.appType('vtb') ? 'vtb-button' : 'rectangle-button'"
                class="mt-6"
                >
                Далее
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import DocsInfo from "@/components/DocsInfo";
import AdditionalForms from "@/components/modal/registration/AdditionalForms";
import EsiaPanel from "@/components/controls/EsiaPanel.vue";
import MarkInfo from "@/components/controls/MarkInfo.vue";

export default {
    components: {EsiaPanel, AdditionalForms, DocsInfo,  MarkInfo},

    computed: {
        additionalData() {
            return this.$root.anketa.additionalData;
        },

        connector() {
            return this.$root.connector;
        },

        anketa() {
            return this.$root.anketa;
        },

        needPhoto() {
            if (
                this.additionalData.filesRequired ||
                this.additionalData.passportRequired ||
                this.additionalData.photoWithPassportRequired
            ) {
                return true;
            }
            return false;
        },

        checkFieldsForEsia(){
            let showEsia = true
            let fields = this.anketa.additionalData;
            if(fields['filesRequired'] || fields['photoWithPassportRequired']){
                showEsia = true
            }else {
                showEsia = false
            }
            return showEsia
        },

        additionalText() {
            let text = "Нам нужно еще немного информации. Пожалуйста, ";

            if (
                this.additionalData.employmentRequired ||
                this.additionalData.additionalPhoneNumberRequired
            ) {
                if (this.needPhoto) {
                    text += "укажите дополнительные данные, прикрепите необходимые документы.";
                } else {
                    text += "укажите дополнительные данные.";
                }
            } else if (this.needPhoto) {
                text += "прикрепите необходимые документы.";
            }

            return text;
        },

    },

    created() {
        this.$root.mindbox("Запросили доп. документы");
    },

    methods: {
        sendDocs() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                const data = {
                    Employment: {
                        employmentType: this.anketa.employmentType.value.value,
                        employer: this.anketa.organizationName.value,
                        position: this.anketa.position.value,
                        employmentPeriod: this.anketa.employmentPeriod.value.value,
                        additionalPhoneNumber: this.anketa.workPhone.cleanValue(),
                        phoneType: "work",
                    },

                    additionalPhoneNumber:
                        this.anketa.additionalPhone.cleanValue(),

                    passportPhoto1: this.anketa.photoPassport.file?.img,
                    passportPhoto2:
                        this.anketa.photoPassportRegistration.file.img,
                    photoWithPassportRequired:
                        this.anketa.photoSPassportWithUser.file.img,
                    filesRequired1:
                        this.anketa.photoSecondDocumentFront.file.img,
                    filesRequired2:
                        this.anketa.photoSecondDocumentBack.file.img,
                };

                let routes = {
                    prevPage: "needMoreData",
                    nextPage: "statusWait",
                };

                this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("nmd_send");
                this.$root.mindbox("Отправил доп. документы");

                this.$emit("changeTemplate", routes, data);

                // this.connector
                //     .getTokenAccessAndRefresh(
                //         this.anketa.phone.valueFirstDigitIs9(),
                //         this.anketa.password.value
                //     )
                //     .then((response) => {
                //         if (response.success) {
                //             localStorage.setItem(
                //                 "tokenAccess",
                //                 response.access
                //             );
                //             localStorage.setItem(
                //                 "tokenRefresh",
                //                 response.refresh
                //             );
                //             this.connector
                //                 .saveDocuments(personalData)
                //                 .then((response) => {
                //                     if (response.success) {
                //                         let data = {
                //                             prevPage: "needMoreData",
                //                             nextPage: "statusWait",
                //                         };
                //                         this.$root.metrika88149184.reachGoal(
                //                             "nmd_send"
                //                         );
                //
                //                         this.$emit("changeTemplate", data);
                //                     } else {
                //                         this.errorText = response.message;
                //                     }
                //                 })
                //                 .catch((response) => {
                //                     console.log(
                //                         response.config,
                //                         response.request,
                //                         response.response
                //                     );
                //                     this.errorText = response.message;
                //                 })
                //                 .finally(() => (this.loading = false));
                //         } else {
                //             this.errorText = response.message;
                //         }
                //     })
                //     .catch((error) => {
                //         if (error.message === "Network Error") {
                //             this.errorText =
                //                 "Произошла невообразимая ситуация!";
                //         } else {
                //             this.errorText = "Вы ввели неправильный пароль";
                //         }
                //     });
            }
        },
        // sendEnrichment(data){
        //     this.connector.saveDocuments(data)
        //         .then(response => {
        //             console.log('AdditionalInfo enrichment response', response);
        //         })
        // }
    },

    data: () => ({
        errorText: "",
        loading: false,

        fields: [
            {
                title: "Сведения о работе",
                items: [
                    "employmentType",
                    "organizationName",
                    "position",
                    "employmentPeriod",
                    "workPhone",
                ],
                conditions: ["employmentRequired"],
            },
            {
                title: "Дополнительный контакт",
                items: ["additionalPhone"],
                conditions: ["additionalPhoneNumberRequired"],
            },
            {
                title: "Паспорт",
                items: [
                    "photoPassport",
                    "photoPassportRegistration",
                    "photoSPassportWithUser",
                ],
                conditions: ["passportRequired", "photoWithPassportRequired"],
            },
            {
                title: "Водительские права или СНИЛС",
                items: ["photoSecondDocumentFront", "photoSecondDocumentBack"],
                conditions: ["filesRequired"],
            },
        ],
    }),
    // mounted() {
    //     console.log('this.anketa.additionalData', this.anketa.additionalData)
    // }
};
</script>

<style lang="scss" scoped>
.need-more-data {
    position: relative;
    &_mark {
        position: relative;
        top: 4px;
        left: 0;
    }
    &_subtitle {
        display: flex;
        margin-top: 50px;
        gap: 10px;
        & > p {
            text-align: left;
            font-family: Roboto, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.4px;
            color: #0A0A0D;
            margin-bottom: 0 !important;
        }
    }
}
</style>
