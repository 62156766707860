import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import anketa from './mixins/anketa'
import connector from "./mixins/connector";
import state from "./mixins/state";
import VueCookies from 'vue-cookies'
Vue.config.productionTip = false
import VueMask from 'v-mask'

Vue.use(VueMask, {
    placeholders: {
        // N: null
        S: /^[а-яА-ЯёЁa-zA-Z0-9]+$/,
        Я: /[\wа-яА-Я]/
    }
});

Vue.use(VueCookies, { expires: '30d'})

new Vue({
    vuetify,
    mixins: [anketa, connector, state],
    render: h => h(App),
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.xs;
        },
    },

    created() {
        this.initMetrika()
    },

    mounted() {
        this.getSessionId()
    },

    data: () => ({
        metrika88149184: {
            reachGoal: () => {
            }
        },
        metrika98546204: {
            reachGoal: () => {
            }
        },
        metrika96799237: {
            reachGoal: () => {
            }
        },
        metrika97329470: {
            reachGoal: () => {
            }
        },
        metrika98070141: {
            reachGoal: () => {
            }
        },
        metrika98069894: {
            reachGoal: () => {
            }
        },
        metrika98072130: {
            reachGoal: () => {
            }
        },

        mindbox(value) {
            return window.mindbox("async", {
                operation: "CashPaylateMetrika",
                data: {
                    customerAction: {
                        customFields: {
                            goalsYandexMetrica: value
                        }
                    },
                    customer: {
                        ids: {
                            clientID: this.anketa.mindboxId
                        }
                    }
                },
                onSuccess: function () {
                },
                onError: function (error) {
                    console.log('error')
                    console.log(error)
                }
            });
        },
        juicySession: '',

    }),

    methods: {
        isSizeAndDown(size) {
            let condition = this.$vuetify.breakpoint[`${size}AndDown`]
            return condition ? true : false
        },
        appType(type) {
            if(Array.isArray(type)){
                for(let t in type){
                    if(type[t] === this.$root.state.type()){
                        return type[t] === this.$root.state.type();
                    }
                }
            }else {
                return type === this.$root.state.type();
            }
        },
        getSessionId() {
            window.addEventListener('sessionready', (e) => {
                    this.juicySession = e.detail.sessionId
                }, false
            );

        },
        initMetrika() {
            if (process.env.NODE_ENV === 'production') {
                (function (m, e, t, r, i, k, a) {
                    m[i] = m[i] || function () {
                        (m[i].a = m[i].a || []).push(arguments)
                    };
                    m[i].l = 1 * new Date();
                    k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
                })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

                window.ym(this.addYandexMetricGoals(), "init", {
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    triggerEvent: true,
                    webvisor: true
                });

                document.addEventListener(`yacounter${this.addYandexMetricGoals()}inited`, () => {
                    this[`metrika${this.addYandexMetricGoals()}`] = window[`yaCounter${this.addYandexMetricGoals()}`];
                })
            }
        },
        addYandexMetricGoals() {
            const counters = [
                {
                    type: 'cash',
                    counter: '88149184'
                },
                {
                    type: 'auto',
                    counter: '98546204'
                },
                {
                    type: 'nordwind',
                    counter: '96799237'
                },
                {
                    type: 'vtb',
                    counter: '97329470'
                },
                {
                    type: 'bankiros',
                    counter: '98069894'
                },
                {
                    type: 'travelata',
                    counter: '98070141'
                },
                {
                    type: 'southwind',
                    counter: '98072130'
                }
            ]
            let currentCounter = null
            for (let i = 0; i < counters.length; i++) {
                if (counters[i].type === this.$root.state.type()) {
                    currentCounter = counters[i].counter
                }
            }
            return currentCounter
        }
    },
}).$mount('#app')

