<template>
<!--    <div class="waiting-esia">-->
<!--        <h1 class="waiting-esia_title">Проверяем Госуслуги</h1>-->
<!--        <p class="waiting-esia_subtitle">Время ожидания пару минут...</p>-->
<!--        <div class="waiting-esia_preloader">-->
<!--            <img v-if="$root.appType('cash') || $root.appType('auto')" src='@/assets/img/preloader-two.gif' />-->
<!--            <img v-if="$root.appType('travelata')" src='@/assets/img/travelata/travelata-preloader.gif' />-->
<!--            <img v-if="$root.appType('vtb')" src='@/assets/img/vtb/vtb_preloader-two.gif' />-->
<!--            <img v-if="$root.appType('nordwind')" src='@/assets/img/nordwind/nordwind_preloader.gif' />-->
<!--            <img v-if="$root.appType('southwind')" src='@/assets/img/southwind/southwind_preloader.gif' />-->
<!--            <img v-if="$root.appType('bankiros')" src='@/assets/img/bankiros/preloader-bankiros.gif' />-->
<!--        </div>-->
<!--    </div>-->

    <div>
        <div v-if="$root.appType('cash') || $root.appType('auto')">
            <div class='main-option-for-app'>
                <h1>Проверяем заявку...</h1>
                <p class="mb-6 mt-6 text-left status-waiting_subtitle">Время ожидания несколько минут</p>
            </div>

            <div class='preloader' v-show='waitNewStatus'>
                <img src='../../assets/img/preloader-two.gif'/>
            </div>
            <p
                class='error-text text-left'
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>

        <div v-if="$root.appType('vtb')">
            <div class='main-option-for-app'>
                <h1 class="vtb_forms_title text-left">{{ !changeText.flag ? changeText.textForChange.title[0] : changeText.textForChange.title[1] }}</h1>
                <div
                    :class="{'status-waiting_flexible_text' : changeText.flag}"
                    class="status-waiting_inline_text"
                >
                    <p  v-show="changeText.flag === false"
                        class="text-left status-waiting_subtitle">
                        {{changeText.textForChange.subtitle[0]}}
                        <span class="status-waiting_subtitle_vtb-second">{{!isMobile ? 'Пожалуйста, не закрывайте окно.': 'Не закрывайте окно.' }}</span>
                        <!-- <span class="status-waiting_subtitle_vtb-second">'Пожалуйста, не закрывайте окно.'</span> -->
                    </p>
                    <p  v-show="changeText.flag === true"
                        class="text-left status-waiting_subtitle">
                        {{ changeText.textForChange.subtitle[1]}}
                    </p>
                    <p v-show="changeText.flag === true" class="status-waiting_subtitle_vtb-second">Пожалуйста, не закрывайте окно.</p>
                </div>
            </div>

            <div class='preloader' v-show='waitNewStatus'>
                <img src='../../assets/img/vtb/vtb_preloader-two.gif'/>
            </div>
            <p
                class='error-text text-left'
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>

        <div v-if="$root.appType('nordwind')">
            <div class='main-option-for-app'>
                <h1 class="nordwind_forms_title text-left">{{ !changeText.flag ? changeText.textForChange.title[0] : changeText.textForChange.title[1] }}</h1>
                <div
                    :class="{'status-waiting_flexible_text' : changeText.flag}"
                    class="status-waiting_inline_text"
                >
                    <p  v-show="changeText.flag === false"
                        class="text-left status-waiting_subtitle">
                        {{changeText.textForChange.subtitle[0]}}
                        <span class="status-waiting_subtitle_nordwind-second">{{!isMobile ? 'Пожалуйста, не закрывайте окно.': 'Не закрывайте окно.' }}</span>
                        <!-- <span class="status-waiting_subtitle_nordwind-second">'Пожалуйста, не закрывайте окно.'</span> -->
                    </p>
                    <p  v-show="changeText.flag === true"
                        class="text-left status-waiting_subtitle">
                        {{ changeText.textForChange.subtitle[1]}}
                    </p>
                    <p v-show="changeText.flag === true" class="status-waiting_subtitle_nordwind-second">Пожалуйста, не закрывайте окно.</p>
                </div>
            </div>

            <div class='preloader nordwind_preloader' v-show='waitNewStatus'>
                <img src='../../assets/img/nordwind/nordwind_preloader.gif'/>
            </div>
            <p
                class='error-text text-left'
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>
        <div v-if="$root.appType('travelata')">
            <div class='main-option-for-app'>
                <h1 class="travelata_forms_title text-left">{{ !changeText.flag ? changeText.textForChange.title[0] : changeText.textForChange.title[1] }}</h1>
                <div
                    :class="{'status-waiting_flexible_text' : changeText.flag}"
                    class="status-waiting_inline_text"
                >
                    <p  v-show="changeText.flag === false"
                        class="text-left status-waiting_subtitle">
                        {{changeText.textForChange.subtitle[0]}}
                        <span class="travelata_status-waiting_subtitle-second">{{!isMobile ? 'Пожалуйста, не закрывайте окно.': 'Не закрывайте окно.' }}</span>
                        <!-- <span class="status-waiting_subtitle_nordwind-second">'Пожалуйста, не закрывайте окно.'</span> -->
                    </p>
                    <p  v-show="changeText.flag === true"
                        class="text-left travelata_status-waiting_subtitle">
                        {{ changeText.textForChange.subtitle[1]}}
                    </p>
                    <p v-show="changeText.flag === true" class="travelata_status-waiting_subtitle-second">Пожалуйста, не закрывайте окно.</p>
                </div>
            </div>

            <div class='preloader nordwind_preloader' v-show='waitNewStatus'>
                <img src='../../assets/img/travelata/travelata-preloader.gif'/>
            </div>
            <p
                class='error-text text-left'
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>

        <BankirosStatusWaiting v-if="$root.appType('bankiros')" :errorAuthTextApi="errorAuthTextApi" />

        <div v-if="$root.appType('southwind')">
            <div class='main-option-for-app'>
                <h1 class="nordwind_forms_title text-left">{{ !changeText.flag ? changeText.textForChange.title[0] : changeText.textForChange.title[1] }}</h1>
                <div
                    :class="{'status-waiting_flexible_text' : changeText.flag}"
                    class="status-waiting_inline_text"
                >
                    <p  v-show="changeText.flag === false"
                        class="text-left status-waiting_subtitle">
                        {{changeText.textForChange.subtitle[0]}}
                        <span class="status-waiting_subtitle_nordwind-second">{{!isMobile ? 'Пожалуйста, не закрывайте окно.': 'Не закрывайте окно.' }}</span>
                        <!-- <span class="status-waiting_subtitle_nordwind-second">'Пожалуйста, не закрывайте окно.'</span> -->
                    </p>
                    <p  v-show="changeText.flag === true"
                        class="text-left status-waiting_subtitle">
                        {{ changeText.textForChange.subtitle[1]}}
                    </p>
                    <p v-show="changeText.flag === true" class="status-waiting_subtitle_nordwind-second">Пожалуйста, не закрывайте окно.</p>
                </div>
            </div>

            <div class='preloader nordwind_preloader' v-show='waitNewStatus'>
                <img src='@/assets/img/southwind/southwind_preloader.gif'/>
            </div>
            <p
                class='error-text text-left'
                v-show='errorAuthTextApi.status'>
                {{ errorAuthTextApi.text }}
            </p>
        </div>
    </div>
</template>

<script>
import BankirosStatusWaiting from "@/components/bankiros/modal/StatusWaiting.vue";

export default {
    name: "WaitingEsia",
    components: {BankirosStatusWaiting},
    data(){
        return {
            requestCounter: 0,
            requestErrors: {
                status: false,
                message: "",
            },
            isRequestSuccess: false,
            isRequestStatusSuccess: true,
            errorAuthTextApi: {
                status: false,
                text: "",
            },
            waitNewStatus: true,
            changeText: {
                //счетчик запроса status
                timePassedCounter: 0,
                // время после которого меняется текст 1 === 1 сек.
                timePassedLimit: 119,
                flag: false,
                textForChange: {
                    title: ['Проверяем заявку', 'Заявка в обработке - скоро все будет готово!'],
                    subtitle: ['Время ожидания несколько минут.', 'В случае необходимости с вами свяжется оператор для уточнения данных. Благодарим за терпение!'],
                    // text: ['Пожалуйста, не закрывайте окно.']
                },
            },
        }
    },
    computed: {
        connector(){
            return this.$root.connector;
        },
        anketa(){
            return this.$root.anketa;
        },
        step(){
            return this.$root.state.esiaStatus.step
        },
        isMobile(){
            console.log('status-waiting', this.$vuetify.breakpoint.xs)
            return this.$vuetify.breakpoint.xs
        }

    },
    methods: {
        getUserDataFromEsia(){
            if(this.anketa.applicationId){
                if(this.isRequestStatusSuccess){
                    this.connector.isStatusApplication()
                        .then(response => {
                            if(response.success){
                                this.isRequestStatusSuccess = true
                                console.log('this.anketa.additionalData...', response.info.required)
                                console.log('isShowEsiaByFields', this.isShowEsiaByFields(response.info.required))
                                if(response.status === 'closed'){
                                    this.$root.state.esiaStatus.usingStep = false
                                    const stepData = {
                                        success: true,
                                        prev: "waitingEsia",
                                        next: "declined",
                                    };
                                    this.$emit("changeTemplate", stepData);
                                }else if(this.step === 3 && response.status === 'needMoreData' && this.isShowEsiaByFields(response.info.required)){
                                    if(this.requestCounter < 3){
                                        this.anketa.additionalData = response.info.required
                                        this.callEsia5(true)

                                    }

                                }
                                else {
                                    this.callEsia5(false)
                                }
                            }

                        })
                    // .catch(error=>{
                    //     console.log(error)
                    //     console.log('status error', this.$root.state.esiaStatus.usingStep)
                    //     this.$root.state.esiaStatus.usingStep = true
                    //     console.log('status error', this.$root.state.esiaStatus.usingStep)
                    // })
                }


            }else {
                // console.log('шаг 2')
                // this.$root.state.esiaStatus.usingStep = true
                // console.log('шаг 3', this.$root.state.esiaStatus.usingStep)

                this.callEsia5(false)

            }
        },

        callEsia5(callEnrichment){
            console.log('enrichment arg', callEnrichment)
            let esiaData = {
                sessionId: localStorage.getItem('sessionId'),
                clientId: null,
                tokenDt: localStorage.getItem('comundaTokenDt'),
                token: localStorage.getItem('comundaToken'),
                serviceClientId: null
            }
            this.connector.startProccess('esia5', esiaData, "POST", 20000)
                .then((response) => {
                    console.log('условие', !response?.status, !response?.status && this.requestCounter >= 3)
                    if(!response?.status && this.requestCounter > 3){
                        console.log(1)
                        this.$root.state.esiaStatus.hasError.esiaClientData = true
                        this.$root.state.esiaStatus.usingStep = true
                    }else {
                        console.log(2)
                        this.$root.state.esiaStatus.hasError.esiaClientData = false

                        if(response.resultProcess.success) {
                            console.log(3)
                            this.$root.state.esiaStatus.data = response.resultProcess
                            // this.$root.state.esiaStatus.usingStep = true
                            console.log('waitingEsia usingStep', this.$root.state.esiaStatus.usingStep)
                            // this.requestCounter = 3
                            if(this.step === 3){
                                console.log(4)
                                // this.$root.state.esiaStatus.usingStep = false
                                this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("auth_nmd");
                                if(callEnrichment){
                                    console.log(5)
                                    this.connector.saveDocuments({})
                                        .then(response => {
                                            console.log('AdditionalInfo enrichment response', response);
                                            this.$root.state.esiaStatus.usingStep = false
                                            clearInterval(this.$root.state.esiaStatus.intervalId)
                                            const stepData = {
                                                success: true,
                                                prev: "waitingEsia",
                                                next: "statusWait",
                                            };
                                            this.$emit("changeTemplate", stepData);

                                        })
                                        .catch(error => {
                                            console.log('enrichment error', error);
                                            this.connector.isStatusApplication()
                                                .then(response => {
                                                    console.log('status response', response)
                                                })
                                        })
                                }else {
                                    console.log(6)
                                    this.$root.state.esiaStatus.usingStep = false
                                    clearInterval(this.$root.state.esiaStatus.intervalId)
                                    console.log('usingStep', this.$root.state.esiaStatus.usingStep)
                                    const stepData = {
                                        success: true,
                                        prev: "waitingEsia",
                                        next: "statusWait",
                                    };
                                    this.$emit("changeTemplate", stepData);
                                }


                            }
                            else if(this.step === 2){
                                console.log(7)
                                this.$root[`metrika${this.$root.addYandexMetricGoals()}`].reachGoal("auth_gos");
                                this.$root.state.esiaStatus.usingStep = true
                                // clearInterval(this.$root.state.esiaStatus.intervalId)
                            }

                            console.log('usingStep', this.$root.state.esiaStatus.usingStep)
                            // this.requestErrors.status = false;
                        }
                        else {
                            console.log(8)
                            setTimeout(() => {
                                this.getUserDataFromEsia();
                            }, 5000);
                        }
                    }

                })
                .catch(() => {

                    if(this.requestCounter < 3){
                        console.log(9)
                        console.log('catch requestCounter')
                        setTimeout(() => {
                            this.getUserDataFromEsia();
                        }, 5000);
                    }else {
                        console.log(10)
                        this.requestErrors.status = true;
                        this.$root.state.esiaStatus.hasError.esiaClientData = true
                        this.$root.state.esiaStatus.usingStep = true

                        this.connector.isStatusApplication()
                            .then(response => {
                                console.log('попал')
                                console.log('status response', response)
                            })

                    }
                })
            this.requestCounter++;
        },
        isShowEsiaByFields(fields){
            let esiaFields = ['filesRequired', 'photoWithPassportRequired'];
            let [filesRequired, photoWithPassportRequired] = esiaFields
            console.log('dis', filesRequired, photoWithPassportRequired)
            for(let field in fields){
                console.log(field, fields[field]);
                if(fields[filesRequired] || fields[photoWithPassportRequired]){
                    return true;
                }else {
                    return false;
                }
                // for(let esiaField of esiaFiels){
                //
                // }
            }
        }

    },
    mounted(){
        let url = location.href.includes('appClientId')
        if(url) {
            // let timeoutId = setTimeout(()=>{
            //     this.getUserDataFromEsia()
            // },500)
            setTimeout(()=>{
                this.getUserDataFromEsia()
            },500)
            // clearTimeout(timeoutId)

        }




    }
}
</script>



<style scoped lang="scss">
    //.waiting-esia {
    //    display: flex;
    //    flex-direction: column;
    //    align-items: center;
    //    &_title {
    //        font-family: Roboto, sans-serif;
    //
    //    }
    //
    //    &_preloader {
    //        width: 50px;
    //        height: 50px;
    //        & > img {
    //            width: 100%;
    //        }
    //    }
    //}

    @import '../../assets/scss/_travelata-variables.scss';

    .loading-button {
        width: 100%;
        height: 60px;
        background: #b32f50 !important;
        border-radius: 12px;

        img {
            width: 25px;
            height: 25px;
        }
    }

    @keyframes rotateLoader {
        0% {
            transform: rotate(0deg) matrix(0, 1, 1, 0, 0, 0);
        }

        100% {
            transform: rotate(360deg) matrix(0, 1, 1, 0, 0, 0);
        }
    }

    .status-waiting {
        &_subtitle {
            color: #656565 !important;
            display: inline;
            text-align: left;

            &_vtb{
                &-second {
                    color: #1976D2;
                    display: inline;
                    text-align: left;
                }

            }
            &_nordwind{
                &-second {
                    margin-top: 20px;
                    color: #D6181E;
                    display: inline;
                    text-align: left;
                }

            }
        }

        &_inline_text {
            margin: 24px 0 !important;
            text-align: left !important;
        }

        &_flexible_text {
            display: flex;
            align-items: start;
            flex-direction: column;

        }

    }

    .text-left {
        align-items: start;
    }

    .travelata_status-waiting {
        &_subtitle {
            @include tr-text();
            font-family: 'OpenSansR';
            color: #707070;
            &-second {
                color: #059BCE;
            }
        }
    }

    status-waiting_.travelata_status-waiting {
        &_subtitle {
            @include tr-text();
            font-family: 'OpenSansR';
            color: #707070;
            &-second {
                color: #059BCE;
            }
        }
    }
</style>
